<template>
  <div
    v-if="
      getUserRoles() == Admin ||
      getUserRoles() == SuperAdmin ||
      getUserRoles() == DataEntry
    "
    class="d-flex justify-content-end align-items-center"
  >
    <b-button variant="primary" @click="edit">تعديل</b-button>
    <b-button variant="outline-primary" class="mx-1" @click="copy">تكرار الكورس</b-button>
    <b-button variant="outline-primary" @click="$router.go(-1)">تراجع </b-button>
    <b-button
      variant="outline-danger"
      class="ml-1"
      @click="removeCourse(courseDetailsDto.id)"
      >حذف</b-button
    >
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getUserRoles } from "@/app/admin/auth/utils";
import { SuperAdmin, Admin, DataEntry } from "@/router";
export default {
  computed: {
    ...mapState({
      courseDetailsDto: ({ courses }) => courses.courseDetailsDto,
    }),
  },
  data() {
    return {
      SuperAdmin,
      Admin,
      DataEntry,
    };
  },
  methods: {
    ...mapActions(["updateCourse", "copyCourse"]),
    getUserRoles,
    removeCourse(id) {
      this.$store.dispatch("deleteCourse", [id]);
      console.log(id);
    },
    edit() {
      this.updateCourse(this.courseDetailsDto);
    },
    copy() {
      this.copyCourse(this.courseDetailsDto.id);
    },
  },
};
</script>
